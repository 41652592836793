import React from 'react';
import Layout from "../components/layout";
import Helmet from "../components/helmet";
import PageContent from '../page_content/supervision';

let config = {
  pageTitle: 'Supervision'
}


export default ({ children, ...props }) => {
    return (
      <Layout className="no-padding-bottom">
        <Helmet title={config.pageTitle}/>
        <PageContent/>
        {children}
      </Layout>
    )
}

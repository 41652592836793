import React from "react";
import Image from "./../components/image";
import Caption from "./../components/caption";

export default props => (
    <>
        <h1 style={{ textAlign: "center" }}>
            Systemisch kunsttherapeutische Supervision
        </h1>

        <div className="page-section" style={{ marginTop: "2rem", display: "flex" }}>
            <div className="has-text-centered wko-supervision-logo">
                <Image filename="Impuls_pro-Supervision_RGB.jpg" alt="Logo Impuls Pro Supervision WKO" />
                <small>

                Mitglied im<br/>
                <a
                    rel="noreferrer noopener"
                    href="https://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=e7bcdf6c-28a5-4b23-8cd3-6cf75746afd9&Name=Wolfgang%20Engelhardt&Standort=Nieder%C3%B6sterreich%20(Bundesland)"
                    >
                    ExpertInnenpool Supervision der WKO
                </a>
                </small>
            </div>
            <div className="card sichtweisen-card has-text-centered">
                <p>
                    <strong>
                        Neue Sichtweisen integrieren mit
                        <br />
                        systemisch-kunsttherapeuthischen Elementen.
                    </strong>
                    <br />
                </p>
                <p>
                    Für Menschen, die im sozialen und
                    <br />
                    psychosozialen Umfeld arbeiten.
                </p>
            </div>
        </div>

        <div
            className="columns page-section no-line"
            style={{ marginTop: "0", marginBottom: "0" }}
        >
            <div className="column is-three-fifths">
                <p>
                    Oft ist es nicht ausreichend rein analytisch und nur auf der
                    Ebene der Sprache an ein Problem oder einen Sachverhalt
                    heranzugehen; es bedarf viel mehr der Einbeziehung von
                    tiefer liegenden, oft unbewussten Motivationen und
                    Verhaltensmustern. Diese können mit unterschiedlichen
                    kreativen Methoden (z. B. Walt-Disney-Methode,
                    Imaginationen, Collagen, Tune-In) auch bildnerisch oder als
                    Gestaltung zum Ausdruck kommen und damit als sozusagen
                    dritte Ebene erheblich zur Klärung der Verstrickungen
                    beitragen. Kreativität wird dabei angeregt, denn sie steckt
                    in jedem und jeder (und es braucht keinerlei malerische
                    Fähigkeiten dazu).
                </p>
            </div>

            <div className="column has-text-centered image-column">
                <figure>
                    <Image filename="korallen.jpg" alt="Korallen" />
                    <Caption>
                        Supervison gewährt Einblick in scheinbar unüberschaubare
                        Systemzusammenhänge.
                    </Caption>
                </figure>
            </div>
        </div>

        <div className="columns page-section" style={{ marginTop: "0" }}>
            <div className="column has-text-centered image-column">
                <figure>
                    <Image filename="anemonenfisch.jpg" alt="Anemonenfisch" />
                    <Caption>
                        Schutz, Vorteile und Abhängigkeiten hängen oft zusammen.
                        Wie hier bei der Symbiose vom Anemonenfisch und seinem
                        für andere Fische giftigen Zuhause. Sein Lebensraum
                        bleibt damit aber sehr begrenzt. Der hierauf bezogene
                        Film "Findet&nbsp;Nemo" zeigt das Dilemma zwischen
                        Sicherheit und Freiheit in aller Deutlichkeit →
                        Supervision verhilft zu einer differenzierten
                        Betrachtung der Bedürfnisse.
                    </Caption>
                </figure>
            </div>
            <div className="column is-three-fifths">
                <p>
                    <strong>Durch die Supervision</strong> gewinnen die
                    Beteiligten neue Inspiration für den Berufsalltag und werden
                    angeregt, die gewohnten Dinge einmal ganz anders zu
                    betrachten. Das weckt Lust Neues auszuprobieren und fördert
                    die Kreativität.
                </p>

                <p>
                    Ein wichtiges Element in der Supervision ist die Anleitung
                    zur Selbstreflexion. Es werden Körper, Geist und Gefühl
                    miteinbezogen. Gleichzeitig fließen die unterschiedlichen
                    Organisationsebenen, von der einzelnen MitarbeiterIn bis zur
                    Führungsebene mit ein. Hauptelement ist es, mit
                    unterschiedlichen Methoden unterschiedliche Sichtweisen auf
                    ein und das selbe Phänomen zu ermöglichen und sie
                    auszuprobieren.
                </p>
            </div>
        </div>
    </>
);
